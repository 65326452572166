.flex-row {
    display: flex;
    flex-direction: row;
}

h6 {
    margin-top: 2rem;
    margin-bottom: 1rem;
}

th {
    text-align: center;
}

.accordion-item {
    border: none;
}

.accordion-button {
    padding: 0;
}

.accordion-header .accordion-button {
    font-size: 14px;
    font-weight: 400;
}

.accordion-header {
    line-height: inherit;
}

.accordion-body {
    padding: 0;
}

.iskanjePriimek .rs-picker-select-menu.rs-picker-select-menu-items {
    display: flex;
    flex-wrap: wrap;
    width: 546px;
}